import { useQuery } from "react-query"
import apiFetch from "../api"
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import Podcast from "../Components/Podcast";
import StoryCard from "../Components/StoryCard";
import { useDocument } from "../hooks/useDocument";
import formatDate from "../utils/formatDate";

const News = () => {
  const {feedId} = useParams();
  const {data: podcasts} = useQuery(["podcasts"], async () => {
    return await apiFetch("/api/podcasts/?ordering=-created_at");
  }, { keepPreviousData: true, enabled: !feedId });
  const {data: feeds} = useQuery(["feeds"], async () => {
    return await apiFetch("/api/feeds/");
  }, { keepPreviousData: true });

  const currentPodcast = useMemo(() => {
    if (!podcasts || feedId) return null;
    return podcasts?.results?.[0] || {};
  }, [podcasts, feedId]);

  const { data: stories } = useQuery(["stories", currentPodcast?.id, feedId], async () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const filter = {
      ordering: "score",
    }
    if (feedId) {
      filter.feed = feedId;
    }
    if (currentPodcast) {
      filter.podcast = currentPodcast.id;
    } else {
      const yesterdayString = yesterday.toISOString();
      filter.created_at__gte = yesterdayString;
    }
    
    const search = new URLSearchParams(filter).toString();
    return await apiFetch(`/api/stories/?${search}`);
  }, { enabled: !!feedId || !!currentPodcast });

  const currentFeed = useMemo(() => {
    if (!feeds || !feedId) return null;
    return feeds?.results?.find(f => f.id.toString() === feedId) || {};
  }, [feeds, feedId]);
  
  const dt = currentPodcast?.created_at ? new Date(currentPodcast.created_at) : new Date();

  useDocument({ title: (currentFeed?.title || "Top Stories") + " - " + formatDate(dt) });

  return (
    <div className="m-auto max-w-4xl">
      <h1>{currentFeed ? currentFeed.title : "Top Stories"} - {formatDate(dt)}</h1>
      {currentPodcast && (
        <Podcast id={currentPodcast.id} />
      )}
      <div className="grid gap-4 grid-cols-1">
        {stories?.results && stories?.results?.map(p => (
          <StoryCard key={p.id} story={p} />
        ))}
      </div>
    </div>
  )
}

export default News;
