import { useMutation, useQuery, useQueryClient } from "react-query";
import apiFetch from "../api";
import { Link, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Breadcrumbs from "../Components/Breadcrumbs";
import Chart from "./Chart";
import Dice from "./Dice";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { FaTrash } from "react-icons/fa";
import { e } from "mathjs";

const SENDERS = {
  1: "Me",
  2: "Bot",
  3: "System"
}

const Collapsible = ({ title, children, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <>
      <div className="flex justify-between w-max max-w-full cursor-pointer" onClick={() => setOpen(!open)}>
        <div>{title}</div>
        <div>{open ? "▲" : "▼"}</div>
      </div>
      {open && <div>{children}</div>}
    </>
  );
}

const CreateAdForm = ({ name, setName, products, setProducts, budget, setBudget, keywords, setKeywords }) => {
  return (
    <div className="card">
      <h4 className="font-bold mb-2 text-center">Create Aew Ad</h4>
      <label className="label">Name</label>
      <input className="mb-2 text-input" type="text" value={name} onChange={(e) => setName(e.target.value)} />
      <label className="mt-2 label">Budget</label>
      <div className="mb-2 flex items-center gap-2">
        <span>$</span>
        <input className="text-input" type="text" value={budget} onChange={(e) => setBudget(e.target.value)} />
      </div>
      <label className="mt-2 label">Products:</label>

      {products.map((product, index) => (
        <div key={index} className={`mt-2 p-2 flex-none min-w-2xl max-w-full rounded-lg border border-gray-200 flex justify-between`}>
          {product.name}
          <button className="text-gray-300" onClick={() => setProducts(products.filter((_, i) => i !== index))}>
            <FaTrash />
          </button>
          </div>
        ))}
        <div className="mt-2 mb-2 p-2 text-center rounded-lg bg-gray-200">
          <a className="text-primary-600" href="#">Add</a>
        </div>
      <label className="mt-2 label">Keywords:</label>
      {keywords.map((product, index) => (
        <div key={index} className={`mt-2 p-2 flex-none min-w-2xl max-w-full rounded-lg border border-gray-200 flex justify-between`}>
          {product.name}
          <div className="flex gap-1">
          <button className="text-primary-600">
            E
          </button>
          <button className="text-primary-600">
            P
          </button>
          <button className="text-primary-600">
            B
          </button>
          <button className="text-gray-300">
            N
          </button>
          <button className="text-gray-300" onClick={() => setKeywords(keywords.filter((_, i) => i !== index))}>
            <FaTrash />
          </button>
          </div>
          </div>
        ))}
        <div className="mt-2 p-2 text-center rounded-lg bg-gray-200">
          <a className="text-primary-600" href="#">Add</a>
        </div>
      <div className="card-footer mt-3 text-right">
        <button className="btn-primary">Start</button>
      </div>
    </div>)
}


const useDefaultState = (initialValue) => {
  const [value, setValue] = useState(null);
  console.log(value, initialValue);
  const setDefault = (newValue) => {
    if (value === initialValue) {
      setValue(null);
    } else {
      setValue(newValue);
    }
  }
  return [value === null ? initialValue : value, setDefault];
}


const useCreateAdState = ({ initialName }) => {
  console.log(initialName);
  const [name, setName] = useDefaultState(initialName);
  const [budget, setBudget] = useDefaultState(100);
  const [products, setProducts] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const data = useMemo(
    () => ({ name, products, budget, keywords }),
    [name, products, budget, keywords]);
  return { name, setName, products, setProducts, budget, setBudget, keywords, setKeywords, data };
}


const Chat = () => {
  const { chatId } = useParams();
  const queryClient = useQueryClient();
  const [text, setText] = useState("");
  const { data: chat } = useQuery(["chats", chatId], async () => {
    return await apiFetch(`/api/chats/${chatId}/`);
  });
  const { data: messages } = useQuery(["messages", chatId], async () => {
    return await apiFetch(`/api/messages/?ordering=-created_at&chat=${chatId}`);
  }, { keepPreviousData: true });
  const initialState = useMemo(() => {
    const data = messages?.results?.find((m) => m.metadata?.name === "createad")?.metadata?.data
    if (data) {
      return JSON.parse(data);
    }
  }, [messages])
  const createAdState = useCreateAdState({ initialName: initialState?.name });

  const sendMessage = useMutation(
    async (message) => {
      return await apiFetch(`/api/messages/`, {
        method: "POST",
        body: JSON.stringify({
          text: message,
          chat: chatId,
        })
      });
    },
    {
      onMutate: async (message) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: ['messages', chatId] })

        // Snapshot the previous value
        const messages = queryClient.getQueryData(['messages', chatId])

        // Optimistically update to the new value
        queryClient.setQueryData(['messages', chatId], (old) => ({ ...old, results: [{ id: -1, text: message, chat: chatId, sender: 1 }, ...old.results] }));

        // Return a context object with the snapshotted value
        return { prev: messages }
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, message, context) => {
        console.log(err);
        setText(message);
        queryClient.setQueryData(['messages', chatId], context.prev)
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['messages', chatId] })
      },
    }
  )

  return (
    <div>
      <div className="mb-4">
        <Breadcrumbs>
          <Breadcrumbs.Item>
            <Link to="/">Home</Link>
          </Breadcrumbs.Item>
          <Breadcrumbs.Separator />
          <Breadcrumbs.Item>
            <Link to="/chats">Chats</Link>
          </Breadcrumbs.Item>
          <Breadcrumbs.Separator />
          <Breadcrumbs.Item>
            <Link to={`/chats/${chatId}`}>{chat?.name}</Link>
          </Breadcrumbs.Item>
        </Breadcrumbs>
      </div>
      <div className="flex gap-5" >
        <div className="f-none h-[calc(100vh-94px)] flex flex-col max-w-2xl w-full">
          <div className="grow p-2 w-full overflow-auto flex flex-col-reverse">
            {sendMessage.isLoading && (
              <>
                <div className={`mt-2 p-2 w-max max-w-full rounded-lg bg-primary-300`}>
                  {SENDERS[2]}: <i className="loading">Typing</i>
                </div>
              </>
            )}
            {chat && messages?.results && messages?.results?.map(message => {
              if (message.metadata?.name === 'vizualize') {
                const spec = JSON.parse(message.metadata?.data || message.text);
                return <Chart key={`chart-${message.id}`} spec={spec} model={chat.data_model} />
              }
              if (message.metadata?.name === 'rolldice') {
                const spec = JSON.parse(message.text);
                return <Dice key={`dice-${message.id}`} spec={spec} />
              }
              if (message.metadata?.name === 'search') {
                const data = JSON.parse(message.metadata?.data);
                const results = data?.results;
                const entityType = data?.entity_type;
                console.log(data)
                return (
                  <>
                    {results.reverse().map((product, index) => (
                      <div key={index} className={`mt-2 p-2 flex-none min-w-2xl max-w-full rounded-lg border border-gray-200 flex justify-between`}>
                        <Markdown remarkPlugins={[remarkGfm]}>{product.name}</Markdown>
                        <button className="btn-primary" onClick={() => {

                          if (entityType === 'products') {
                            if (createAdState.products.find(p => p.name === product.name)) {
                              return;
                            }
                            createAdState.setProducts([...createAdState.products, product]);
                          }
                          if(entityType === 'keywords') {
                            if (createAdState.keywords.find(p => p.name === product.name)) {
                              return;
                            }
                            createAdState.setKeywords([...createAdState.keywords, product]);
                          }
                        }}>+</button>
                      </div>
                    ))}
                    <span>
                      Search: {data?.query}
                    </span>
                  </>
                );
              }
              if (message.metadata?.name === 'createad') {
                return (
                  <div key={message.id} className={`mt-2 p-2 w-max max-w-full rounded-lg bg-primary-300`}>
                    <i className="font-italic">Creating Ad</i>
                  </div>
                );
              }
              if (message.sender === 3) {
                return (
                  <div key={message.id} className={`mt-2 p-2 w-max max-w-full rounded-lg bg-gray-300`}>
                    <Collapsible title={SENDERS[message.sender]} >
                      <Markdown remarkPlugins={[remarkGfm]}>{message.text}</Markdown>
                    </Collapsible>
                  </div>
                )
              }
              return (
                <div key={message.id} className={`mt-2 p-2 w-max max-w-full rounded-lg ${message.sender === 1 ? 'bg-gray-300' : ''}`}>
                  <Markdown remarkPlugins={[remarkGfm]}>{message.text}</Markdown>
                </div>
              )
            })}
            {messages?.length === 0 && <div className="text-center">No messages found</div>}
          </div>
          <div className="flex flex-col mt-3">
            <textarea rows={3} className="text-input grow mb-3" type="text" value={text} onChange={(e) => setText(e.target.value)} onKeyDown={(e) => {
              // allow shift enter to make a new line
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                sendMessage.mutate(text);
                setText("");
              }
            }} />
            <div className="text-right">
              <button className="btn-primary" onClick={() => {
                sendMessage.mutate(text);
                setText("");
              }}>Send</button>
            </div>
          </div>
        </div>
        <div className="mt-4 w-full max-w-lg">
          {chat && messages?.results && messages?.results?.map(message => {

            if (message.metadata?.name === 'createad') {
              return <CreateAdForm {...createAdState} />
            }
            return null;
          })}
        </div>
      </div>
    </div>
  )

}

export default Chat;
